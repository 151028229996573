<template>
<div id = "app">
    <Processing />
</div>
</template>

<script>

import Processing from "./views/index.vue"

export default {
    components: {
        Processing
    }
}
</script>

<style lang = "scss">

#app {
   padding: 0;
   height: 100%;
   width: 100%;
}
</style>
