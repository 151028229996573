<template>
<div :key = "Math.random()" :style = "{height:height,width:width}" />
</template>

<script>
import * as  echarts from 'echarts'
import { debounce } from "@/util";

require('echarts/theme/macarons')

export default {
    props: {
        width: {
            type: String ,
            default: '100%'
        } ,
        height: {
            type: String ,
            default: '100%'
        } ,
        chartData: {
            type: Object ,
            required: true
        }
    } ,
    data() {
        return {
            chart: null
        }
    } ,
    watch: {
        chartData: {
            deep: true ,
            handler(val) {
                this.setOptions(val)
            }
        }
    } ,
    mounted() {
        this.initChart()
        this.__resizeHandler = debounce(() => {
            if (this.chart) {
                this.chart.resize()
                this.initChart()
            }
        } , 100)
        window.addEventListener('resize' , this.__resizeHandler)
    } ,
    beforeDestroy() {
        if (!this.chart) {
            return
        }
        window.removeEventListener('resize' , this.__resizeHandler)
        this.chart.dispose()
        this.chart = null
    } ,
    methods: {
        initChart() {
            this.chart = echarts.init(this.$el , 'macarons')
            this.setOptions(this.chartData)
        } ,
        setOptions({
            name ,
            data
        }) {
            this.chart.setOption({
                grid: {
                    left: '5%' ,
                    right: '15%' ,
                    bottom: '0' ,
                    top: this.echartsToRem(25) ,
                    containLabel: true
                } ,
                tooltip: {
                    show: false
                } ,
                xAxis: {
                    show: false ,
                    type: 'value'
                } ,
                yAxis: [
                    {
                        type: 'category' ,
                        inverse: true ,
                        axisLabel: {
                            show: true ,
                            textStyle: {
                                color: '#fff' ,
                                fontSize: this.echartsToRem(16)
                            }
                        } ,
                        splitLine: {
                            show: false
                        } ,
                        axisTick: {
                            show: false
                        } ,
                        axisLine: {
                            show: false
                        } ,
                        data: data.name
                    }
                ] ,
                series: [
                    {
                        name: '重量' ,
                        type: 'pictorialBar' ,
                        symbol: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADoAAAA6CAMAAADWZboaAAAAZlBMVEUAAABe3uVe3+Vf3uVf3+Zf3uVg3+Zg3+Zf3+Vi4OZh4OZg3+Z86/Bh3+Zi4Odj4Odi4OZ86/B76/B86/Bj4ed56+9x5+xn4umB7/N87PB36e+A7/N+7fF/7vJ/7vJ+7fGA7/OB7/PReX+lAAAAIXRSTlMABQkVDREmIhk3MR10LEFFPHh7cUprXE35h2XnqMLAp+mHAG9cAAAB5ElEQVRIx83WjU7CMBQFYIoiKMqU/XUboHv/l/Tce7t2XamDNSacETEmX86tlK2rx4py150o+MstMBLwWRfHKo6JCVxLnvmFGBjFQ58oF1//sUZhGy/ClSTWObgnL4O+bkeN4nY2okfNMbkRt9/vtxz8InoTsWplJSCzFxPmO8+GpSIByX3YQAuGDWtRKhKjCnxDXhF6Z4yxnZ20Wgko7BMRDmxtSGVaI4kdTIgb+zTYoJQlIMlDlmUFgrcDWWC201qSayqlTkiCddWWeV62VU0YlnpRi9VOKaSUsiyq/N0krwq2Ugt7lVpZl5BfHNiytjagMi+XYp0kCR45hMlivVQrE/uU5pXSrCB5bM6d1t2lOZItMqmliT3q5uVxqxzyW/ccfYLNKx7ZTeykMvNyac2yt2Fbc61MHLSC0rwoxbiNdlQ3GBm1NLHQsHUrtEXppR/ljNpW6DbSCoqlFiVoN6YdaFlgsSFVPs1BdT8OaB5QyQzVcaqWDows/zepxR8ObLglTrdtCRVuRNj4Rrxh+//0ke2f8KVL+Kon3GCSbmsJN9OUW3j6g0Ns+LgCij2u0h+Sghc8mlMPBMgdx5DFh59VmOVHrvmDnoNxCz3J7MFWsMuaLyR089xz/xhlfijvwutR8gv3zk6BLUUeCgAAAABJRU5ErkJggg==' ,
                        symbolSize: [ this.echartsToRem(50) , this.echartsToRem(50) ] ,
                        symbolOffset: [ this.echartsToRem(20) , 0 ] ,
                        z: 12 ,
                        itemStyle: {
                            normal: {
                                color: '#fff'
                            }
                        } ,
                        symbolPosition: 'end' ,
                        data: data.value
                    } , {
                        name: '金额' ,
                        type: 'bar' ,
                        zlevel: 1 ,
                        label: {
                            show: true ,
                            position: 'right' ,
                            formatter: function(params) {
                                return params.value + '吨';
                            } ,
                            textStyle: {
                                fontSize: this.echartsToRem(14) ,
                                color: '#02f0ff'
                            }
                        } ,
                        itemStyle: {
                            normal: {
                                barBorderRadius: this.echartsToRem(30) ,
                                color: '#02f0ff'
                            }
                        } ,
                        barWidth: this.echartsToRem(10) ,
                        data: data.value
                    } ,
                    {
                        name: '背景' ,
                        type: 'bar' ,
                        barWidth: this.echartsToRem(10) ,
                        barGap: '-100%' ,
                        data: data.maxValue ,
                        itemStyle: {
                            normal: {
                                color: '#001f26' ,
                                barBorderRadius: this.echartsToRem(30)
                            }
                        }
                    }
                ]
            } , true)
        }
    }
}
</script>
