<template>
<div class="monitor-card-title">
    <div class="monitor-card-img"></div>
    <div v-if="name" class="monitor-card-text" :style="{color: type?'#fff': ''}">
        {{ name }}
    </div>
    <div v-else class="monitor-card-tab">
        <div :style="{color: currentIndex === 0?'#02F0FF': ''}" @click="handleTab(0)">本月圾分</div>
        <div :style="{color: currentIndex === 1?'#02F0FF': ''}" @click="handleTab(1)">本月金币</div>
    </div>

</div>
</template>

<script>
export default {
    name: 'CardTitle',
    data() {
        return {}
    },
    props: {
        name: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: ''
        },
        currentIndex: {
            type: Number,
            default: null
        }
    },
    created() {
    },
    methods: {
        handleTab(type) {
            this.$emit('handleIndex', type)
        }
    }
}
</script>

<style scoped lang="scss">
    .monitor-card-title{
        width: 100%;
        height: 48px;
        font-size: 24px;
        font-weight: 500;
        color: #02F0FF;
        line-height: 48px;
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        .monitor-card-img{
            width: 66px;
            background: url('~@/assets/images/bigdata/bg-y.png') no-repeat;
            background-size: 100%;
        }
        .monitor-card-text{
            padding-left: 10px;
            width: calc(100% - 66px);
            background: url('~@/assets/images/bigdata/bg-x.png');
            background-size: auto 100%;
            display: flex;
            justify-content: space-between;
            span{
               span{
                   cursor: pointer;
                   margin-right: 20px;
               }
            }
        }
        .monitor-card-tab{
            padding-left: 10px;
            width: calc(100% - 66px);
            background: url('~@/assets/images/bigdata/bg-x.png');
            background-size: auto 100%;
            display: flex;
            div{
                color: #fff;
                margin-right: 50px;
                cursor: pointer;
            }
        }
    }
</style>
