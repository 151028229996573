<template>
<div class = "processing-con-middle">
    <div class = "processing-ball">
        <div class = "processing-ball-box">
            <div class = "processing-ball-p processing-ball-1"></div>
            <div class = "processing-ball-p processing-ball-2"></div>
            <div class = "processing-ball-p processing-ball-3"></div>
            <div class = "processing-ball-p processing-ball-4"></div>
            <div v-for = "(item,i) in dataInfo" :key = "item.num+i" :class =
                "'processing-ball-item processing-ball-item'+(i+1)">
                <p>{{ item.title }}</p>
                <div>{{ parseFloat(item.num).toFixed(2) }}<span>t</span></div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { reduction } from "@/api/bigdata";

export default {
    name: 'Index' ,
    data() {
        return {
            timer: null ,
            dataInfo: []
        }
    } ,
    created() {
        this.fetchData()
        this.setIntervalTimer()
    } ,
    beforeDestroy() {
        clearInterval(this.timer)
        this.timer = null
    } ,
    methods: {
        setIntervalTimer() {
            this.timer = setInterval(() => {
                this.fetchData()
            } , 70000)
        } ,
        async fetchData() {
            let { data } = await reduction({});
            this.dataInfo = data.data;
            console.log(this.dataInfo , "asasa")
        }
    }
}
</script>

<style scoped lang = "scss">
.processing-con-middle {
   width: 1048px;
   height: 100%;
   position: relative;
   .processing-ball-box {
      position: relative;
      width: 100%;
      height: 100%;
      .processing-ball-p {
         position: absolute;
         top: 0;
         left: 0;
         right: 0;
         bottom: 0;
         margin: auto;
      }
      .processing-ball-1 {
         width: 95%;
         height: 95%;
         border-radius: 50%;
         background: url('~@/assets/images/bigdata/ball1.png') center center no-repeat;
         background-size: contain;
         animation: rot 15s linear infinite;
      }
      .processing-ball-2 {
         width: 520px;
         height: 520px;
         border-radius: 50%;
         background: url('~@/assets/images/bigdata/ball2.png') center center no-repeat;
         background-size: contain;
         animation: rerot 15s linear infinite;
      }
      .processing-ball-3 {
         width: 280px;
         height: 280px;
         border-radius: 50%;
         background: url('~@/assets/images/bigdata/ball3.png') center center no-repeat;
         background-size: contain;
         //animation: rot 5s linear infinite;
      }
      .processing-ball-4 {
         width: 95%;
         height: 95%;
         background: url('~@/assets/images/bigdata/ball4.png') center center no-repeat;
         background-size: contain;
      }
   }
   //定义动画
   @keyframes rot {
      0% {
         -webkit-transform: rotate(0deg);
      }
      25% {
         -webkit-transform: rotate(90deg);
      }
      50% {
         -webkit-transform: rotate(180deg);
      }
      75% {
         -webkit-transform: rotate(270deg);
      }
      100% {
         -webkit-transform: rotate(360deg);
      }
   }
   @keyframes rerot {
      0% {
         -webkit-transform: rotate(0deg);
      }
      25% {
         -webkit-transform: rotate(-90deg);
      }
      50% {
         -webkit-transform: rotate(-180deg);
      }
      75% {
         -webkit-transform: rotate(-270deg);
      }
      100% {
         -webkit-transform: rotate(-360deg);
      }
   }
   .processing-ball {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 688px;
      height: 688px;
      .processing-ball-item {
         background: url('~@/assets/images/bigdata/cir.png') no-repeat;
         background-size: 100% 100%;
         width: 133px;
         height: 133px;
         text-align: center;
         display: flex;
         justify-content: center;
         flex-direction: column;
         align-items: center;
         position: absolute;
         p {
            width: 120px;
            font-size: 14px;
            font-weight: 400;
            color: #02f0ff;
            margin-bottom: 10px;
         }
         div {
            font-size: 24px;
            font-weight: 400;
            color: #02f0ff;
            span {
               font-size: 16px;
               font-weight: 400;
               color: #ffffff;
            }
         }
      }
      .processing-ball-item1 {
         top: -61px;
         left: 45px;
      }
      .processing-ball-item2 {
         top: -61px;
         right: 45px;
      }
      .processing-ball-item3 {
         top: 180px;
         left: -110px;
      }
      .processing-ball-item4 {
         top: 180px;
         right: -110px;
      }
      .processing-ball-item5 {
         top: 400px;
         left: -100px;
      }
      .processing-ball-item6 {
         top: 390px;
         right: -100px;
      }
      .processing-ball-item7 {
         bottom: -80px;
         left: 133px;
      }
      .processing-ball-item8 {
         bottom: -80px;
         right: 133px;
      }
   }
}
</style>
