import Vue from 'vue';

Vue.mixin({
    methods: {
        echartsToRem(val, initWidth = 1920) {
            let nowClientWidth = document.documentElement.clientWidth;
            return val * (nowClientWidth / initWidth);
        }
    }
})
