<template>
<div :key = "Math.random()" :style = "{height:height,width:width}" />
</template>

<script>
import { debounce } from "@/util";
import * as echarts from "echarts";
// echarts theme
require('echarts/theme/macarons')

export default {
    props: {
        width: {
            type: String ,
            default: '100%'
        } ,
        height: {
            type: String ,
            default: '100%'
        } ,
        chartData: {
            type: Object ,
            required: true
        }
    } ,
    data() {
        return {
            chart: null
        }
    } ,
    watch: {
        chartData: {
            deep: true ,
            handler(val) {
                this.setOptions(val)
            }
        }
    } ,
    mounted() {
        this.initChart()
        this.__resizeHandler = debounce(() => {
            if (this.chart) {
                this.chart.resize()
                this.initChart()
            }
        } , 100)
        window.addEventListener('resize' , this.__resizeHandler)
    } ,
    beforeDestroy() {
        if (!this.chart) {
            return
        }
        window.removeEventListener('resize' , this.__resizeHandler)
        this.chart.dispose()
        this.chart = null
    } ,
    methods: {
        initChart() {
            this.chart = echarts.init(this.$el , 'macarons')
            this.setOptions(this.chartData)
        } ,
        setOptions({
            name ,
            data
        }) {
            let xData = data.name;
            let yData = data.value;
            this.chart.setOption({
                grid: {
                    left: '-5%' ,
                    right: '0' ,
                    bottom: '5%' ,
                    top: '15%' ,
                    containLabel: true
                } ,
                tooltip: {
                    show: true
                } ,
                animation: false ,
                xAxis: [
                    {
                        type: 'category' ,
                        data: xData ,
                        axisTick: {
                            alignWithLabel: true
                        } ,
                        axisLine: {
                            show: false
                        } ,
                        axisLabel: {
                            textStyle: {
                                color: '#fff' ,
                                fontSize: this.echartsToRem(14)
                            } ,
                            margin: this.echartsToRem(30)
                        }
                    }
                ] ,
                yAxis: [
                    {
                        show: false
                    }
                ] ,
                series: [
                    {
                        name: '' ,
                        type: 'pictorialBar' ,
                        symbolSize: [ this.echartsToRem(24) , this.echartsToRem(6) ] ,
                        symbolOffset: [ 0 , this.echartsToRem(-3) ] ,
                        symbolPosition: 'end' ,
                        z: 12 ,
                        label: {
                            normal: {
                                show: true ,
                                position: 'top' ,
                                fontSize: this.echartsToRem(14) ,
                                color: '#02f0ff' ,
                                formatter: function(params) {
                                    return params.value + '吨';
                                }
                            }
                        } ,
                        color: '#00d9e7' ,
                        data: yData
                    } ,
                    {
                        name: '' ,
                        type: 'pictorialBar' ,
                        symbolSize: [ this.echartsToRem(24) , this.echartsToRem(6) ] ,
                        symbolOffset: [ 0 , this.echartsToRem(7) ] ,
                        // "barWidth": "20",
                        z: 12 ,
                        color: '#00d9e7' ,
                        data: yData
                    } ,
                    {
                        name: '' ,
                        type: 'pictorialBar' ,
                        symbolSize: [ this.echartsToRem(30) , this.echartsToRem(9) ] ,
                        symbolOffset: [ 0 , this.echartsToRem(12) ] ,
                        z: 10 ,
                        itemStyle: {
                            normal: {
                                color: 'transparent' ,
                                borderColor: '#02acb9' ,
                                borderType: 'solid' ,
                                borderWidth: this.echartsToRem(1)
                            }
                        } ,
                        data: yData
                    } ,
                    {
                        name: '' ,
                        type: 'pictorialBar' ,
                        symbolSize: [ this.echartsToRem(43) , this.echartsToRem(12) ] ,
                        symbolOffset: [ 0 , this.echartsToRem(18) ] ,
                        z: 10 ,
                        itemStyle: {
                            normal: {
                                color: 'transparent' ,
                                borderColor: '#014952' ,
                                borderType: 'solid' ,
                                borderWidth: this.echartsToRem(2)
                            }
                        } ,
                        data: yData
                    } ,
                    {
                        type: 'bar' ,
                        barWidth: this.echartsToRem(24) ,
                        itemStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(0 , 0 , 0 , 1 , [
                                    {
                                        offset: 0 ,
                                        color: '#00a0aa'
                                    } ,
                                    {
                                        offset: 1 ,
                                        color: ' rgba(2, 240, 255, 0.1)'
                                    }
                                ])
                            }
                        } ,
                        data: yData
                    }
                ]
            } , true)
        }
    }
}
</script>
