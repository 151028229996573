<template>
<div class = "processing-con-left">
    <div class = "processing-price">
        <card-title name = "收货价格走势"></card-title>
        <div class = "clearing-chat">
            <price-chart v-if = "priceData.data.legend.length" :chart-data = "priceData"></price-chart>
        </div>
    </div>
    <div class = "processing-handle">
        <card-title name = "本月处理数量"></card-title>
        <div class = "clearing-chat" :style = "{height: (handleData.data.value.length * 50 + 30) / 192 +'rem'}">
            <handle-chart :chart-data = "handleData" v-if = "handleData.data.value.length"></handle-chart>
        </div>
    </div>
</div>
</template>

<script>
import CardTitle from '../../components/CardTitle';
import PriceChart from './PriceChart';
import HandleChart from './HandleChart';
import { disposeMonthWeightBySiteIds , trend } from '@/api/bigdata';
import * as echarts from 'echarts' // echarts theme
export default {
    name: 'Index' ,
    components: {
        CardTitle ,
        PriceChart ,
        HandleChart
    } ,
    data() {
        return {
            timer: null ,
            priceData: {
                name: '收货价格走势' ,
                data: {
                    colorList: [
                        '255,214,0' ,
                        '56,193,67' ,
                        '36,164,255' ,
                        '46,199,201' ,
                        '182,162,222' ,
                        '90,177,239' ,
                        '255,185,128' ,
                        '216,122,128' ,
                        '141,152,179' ,
                        '87,207,180' ,
                        '145,144,218' ,
                        '203,144,218' ,
                        '236,105,185'
                    ] ,
                    legend: [] ,
                    xLabel: [] ,
                    series: []
                }
            } ,
            handleData: {
                name: '本月处理数量' ,
                data: {
                    name: [] ,
                    value: [] ,
                    maxValue: []
                }
            }
        }
    } ,
    created() {
        this.fetchData()
        this.fetchHandleData()
        this.setIntervalTimer()
    } ,
    beforeDestroy() {
        clearInterval(this.timer)
        this.timer = null
    } ,
    methods: {
        setIntervalTimer() {
            this.timer = setInterval(() => {
                this.fetchData()
            } , 25000)
        } ,
        // 本月处理数量
        async fetchData() {
            let { data } = await trend({});
            data = data.data;
            const xLabel = Object.keys(data)
            if (!xLabel.length) {
                return
            }
            const legend = data[xLabel[0]].map(item => { return item.garbageName })
            const series = []
            const garbageList = []
            legend.forEach(item => {
                const obj = {
                    garbageName: item ,
                    priceList: []
                }
                xLabel.forEach(it => {
                    obj.priceList.push(
                    parseFloat(data[it].find(iz => { return iz.garbageName === item }).price).toFixed(2))
                })
                garbageList.push(obj)
            })
            
            garbageList.forEach((item , index) => {
                series.push({
                    name: item.garbageName ,
                    type: 'line' ,
                    symbol: 'circle' , // 默认是空心圆（中间是白色的），改成实心圆
                    showAllSymbol: true ,
                    symbolSize: this.echartsToRem(5) ,
                    yAxisIndex:  0,
                    smooth: true ,
                    lineStyle: {
                        normal: {
                            width: this.echartsToRem(5) ,
                            color: `rgba(${ this.priceData.data.colorList[index] },1)`
                        }
                    } ,
                    tooltip: { show: true } ,
                    areaStyle: {
                        normal: {
                            color: new echarts.graphic.LinearGradient(0 , 0 , 0 , 1 , [
                                {
                                    offset: 0 ,
                                    color: `rgba(${ this.priceData.data.colorList[index] },0.3)`
                                } ,
                                {
                                    offset: 1 ,
                                    color: `rgba(${ this.priceData.data.colorList[index] },0)`
                                }
                            ] , false) ,
                            shadowColor: `rgba(${ this.priceData.data.colorList[index] },0)` ,
                            opacity: 0.5 ,
                            shadowBlur: this.echartsToRem(20)
                        }
                    } ,
                    data: item.priceList
                })
            })
            this.priceData.data.xLabel = xLabel
            this.priceData.data.legend = legend
            this.priceData.data.series = series
        } ,
        // 本月处理数量
        async fetchHandleData() {
            let { data } = await disposeMonthWeightBySiteIds();
            data = data.data;
            this.handleData.data.name = data.map(item => { return item.garbageName })
            this.handleData.data.value = data.map(item => { return parseFloat(item.weight).toFixed(2) })
            let maxNum5 = Math.max.apply(null , this.handleData.data.value)
            const list = []
            for (let i = 0; i < this.handleData.data.value.length; i++) {
                list.push(maxNum5)
            }
            this.handleData.data.maxValue = list
        }
    }
}
</script>

<style scoped lang = "scss">
.processing-con-left {
   width: 404px;
   //height: 100%;
   .clearing-chat {
      height: calc(100% - 48px);
   }
   .processing-price {
      overflow: hidden;
      //height: 632;
      height: 64.4898%;
   }
   .processing-handle {
      overflow: hidden;
      //height: 632;
      height: 35.5102%;
   }
}
</style>
