<template>
<div class = "processing-con-right">
    <div class = "processing-year">
        <card-title name = "年度合计数据"></card-title>
        <div class = "processing-year-box">
            <div class = "processing-year-card">
                <div class = "processing-year-card-img"></div>
                <p>资源化</p>
                <div class = "processing-year-card-num">
                    <countTo
                        :startVal = '0' :endVal = 'dataInfo["1"]' :duration = '5000'
                        :decimals = "2"></countTo>
                    吨
                </div>
            </div>
            <div class = "processing-year-card processing-year-card2">
                <div class = "processing-year-card-img"></div>
                <p>减量化</p>
                <div class = "processing-year-card-num">
                    <countTo
                        :startVal = '0' :endVal = 'dataInfo["2"]' :duration = '5000'
                        :decimals = "2"></countTo>
                    吨
                </div>
            </div>
            <div class = "processing-year-card processing-year-card3">
                <div class = "processing-year-card-img"></div>
                <p>无害化</p>
                <div class = "processing-year-card-num">
                    <countTo
                        :startVal = '0' :endVal = 'dataInfo["3"]' :duration = '5000'
                        :decimals = "2"></countTo>
                    吨
                </div>
            </div>
        </div>
    </div>
    <div class = "processing-stock">
        <card-title name = "库存总量"></card-title>
        <div class = "clearing-chat">
            <stock-chart :chart-data = "stockData"></stock-chart>
        </div>
    </div>
    <div class = "processing-remind">
        <card-title name = "可出库提醒"></card-title>
        <div class = "processing-remind-box">
            <div class = "processing-remind-text">
                <div class = "processing-remind-text-row" v-for = "(item, index) in remindList" :key = "index">
                    <div class = "processing-remind-name">{{ item.siteName }}</div>
                    <div class = "processing-remind-type">{{ item.garbageName }}</div>
                    <div class = "processing-remind-value">{{ parseFloat(item.storeWeight).toFixed(2) }}吨</div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import CountTo from 'vue-count-to'
import CardTitle from '../../components/CardTitle';
import StockChart from './StockChart';
import { canOut , moreWeight , summation } from "@/api/bigdata";

export default {
    name: 'Index' ,
    components: {
        CountTo ,
        CardTitle ,
        StockChart
    } ,
    data() {
        return {
            timer: null ,
            dataInfo: {
                1: 0 ,
                2: 0 ,
                3: 0
            } ,
            remindList: [] ,
            stockData: {
                name: '库存总量' ,
                data: {
                    name: [] ,
                    value: [] ,
                    maxValue: []
                }
            } ,
            stockTimer: null ,
            stockInfo: null ,
            totalCount: 0 ,
            count: 0
        }
    } ,
    created() {
        this.fetchData()
        this.fetchRemindData()
        this.fetchStockData()
        this.setIntervalTimer()
    } ,
    beforeDestroy() {
        clearInterval(this.timer)
        this.timer = null
        clearInterval(this.stockTimer)
        this.stockTimer = null
    } ,
    methods: {
        setIntervalTimer() {
            this.timer = setInterval(() => {
                this.fetchData()
                this.fetchRemindData()
                this.fetchStockData()
            } , 70000)
        } ,
        // 清运总量（本月）
        async fetchData() {
            const { data } = await summation();
            data.data.forEach(item => {
                this.dataInfo[item.mold] = item.num
            })
        } ,
        // 清运总量（本月）
        async fetchRemindData() {
            const { data } = await canOut({});
            this.remindList = data.data
        } ,
        // 库存总量
        async fetchStockData() {
            const { data } = await moreWeight();
            this.totalCount = 0
            this.stockInfo = null
            const names = data.data.map(item => { return item.garbageName })
            this.totalCount = Math.ceil(names.length / 5)
            if (this.totalCount > 1) {
                this.stockInfo = data.data
                this.stockData.data.name = data.data.map(item => { return item.garbageName })
                    .slice(5 * this.count , 5 * ( this.count + 1 ))
                this.stockData.data.value = data.data.map(item => { return parseFloat(item.Weight).toFixed(2) })
                    .slice(5 * this.count , 5 * ( this.count + 1 ))
                
                this.count++
                
                this.stockTimer = setInterval(() => {
                    this.stockData.data.name = data.data.map(item => { return item.garbageName })
                        .slice(5 * this.count , 5 * ( this.count + 1 ))
                    this.stockData.data.value = data.data.map(item => { return parseFloat(item.Weight).toFixed(2) })
                        .slice(5 * this.count , 5 * ( this.count + 1 ))
                    this.count++
                    if (this.count > this.totalCount - 1) {
                        this.count = 0
                        clearInterval(this.stockTimer)
                        this.stockTimer = null
                    }
                } , Math.ceil(25000 / this.totalCount))
            } else {
                this.stockData.data.name = data.data.map(item => { return item.garbageName })
                this.stockData.data.value = data.data.map(item => { return parseFloat(item.Weight).toFixed(2) })
            }
        }
    }
}
</script>

<style scoped lang = "scss">
.processing-con-right {
   width: 404px;
   height: 100%;
   .clearing-chat {
      height: calc(100% - 48px);
   }
   .processing-year {
      overflow: hidden;
      //height: 632;
      height: 32.1429%;
      .processing-year-box {
         padding-top: 20px;
         display: flex;
         justify-content: space-between;
         height: calc(100% - 48px);
         .processing-year-card {
            width: 120px;
            text-align: center;
            .processing-year-card-img {
               width: 120px;
               height: 50%;
               background: url('~@/assets/images/bigdata/light1.png') center no-repeat;
               background-size: auto 100%;
            }
            p {
               font-size: 16px;
               font-weight: 400;
               color: #ffffff;
               margin: 10px 0;
            }
            .processing-year-card-num {
               font-size: 12px;
               color: #ffffff;
               display: flex;
               align-items: center;
               justify-content: center;
               width: 100%;
               span {
                  margin-right: 5px;
                  font-size: 24px;
                  color: #02f0ff;
               }
            }
         }
         .processing-year-card2 {
            .processing-year-card-img {
               background: url('~@/assets/images/bigdata/light2.png') center no-repeat;
               background-size: auto 100%;
            }
            .processing-year-card-num {
               span {
                  color: #ffd600;
               }
            }
         }
         .processing-year-card3 {
            .processing-year-card-img {
               background: url('~@/assets/images/bigdata/light3.png') center no-repeat;
               background-size: auto 100%;
            }
            .processing-year-card-num {
               span {
                  color: #1273db;
               }
            }
         }
      }
   }
   .processing-stock {
      overflow: hidden;
      //height: 632;
      height: 36.9388%;
   }
   .processing-remind {
      overflow: hidden;
      //height: 632;
      height: 30.9183%;
   }
   .processing-remind-box {
      overflow: hidden;
      margin-top: 6px;
      height: calc(100% - 42px);
   }
   .processing-remind-text-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 32px;
      margin-top: 18px;
      background: url('~@/assets/images/bigdata/remind.png') no-repeat;
      background-size: contain;
      padding: 0 50px;
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      .processing-remind-type {
         font-size: 16px;
         font-weight: 600;
         color: #ffd600;
         width: 94px;
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
      }
      .processing-remind-name {
         width: 140px;
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
      }
      .processing-remind-value {
         width: 70px;
         text-align: right;
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
      }
   }
   /* ---------------------------动画--------------------------------- */
   @keyframes textTrans {
      0% {
      }
      100% {
         transform: translateY(-50%);
      }
   }
   /* 调用动画 */
   .processing-remind .processing-remind-text {
      /* //infinite永久调用动画 */
      animation: textTrans 25s linear infinite;
   }
   /*鼠标划入 停止动画  */
   .processing-remind .processing-remind-text:hover {
      animation-play-state: paused;
   }
}
</style>
