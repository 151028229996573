import { request } from "@/util/index.js";


//年度合计报表
export function summation() {
    return request({
        url: `/data/summation.json` ,
        type: "GET"
    });
}

// 可出库提醒
export function canOut() {
    return request({
        url: `/data/canOut.json`
    });
}

// 库存总量
export function moreWeight() {
    return request({
        url: `/data/moreWeight.json`
    });
}

// 处理数量
export function disposeMonthWeightBySiteIds() {
    return request({
        url: `/data/disposeMonthWeightBySiteIds.json`
    });
}


//中间部分
export function reduction() {
    return request({
        url: `/data/reduction.json`
    });
}

// 收货价格走势
export function trend() {
    return request({
        url: `/data/trend.json`
    });
}
