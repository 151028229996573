<template>
<div :key = "Math.random()" :style = "{height:height,width:width}" />
</template>

<script>
import * as  echarts from 'echarts'
// echarts theme
import { debounce } from '@/util'

require('echarts/theme/macarons')

export default {
    props: {
        width: {
            type: String ,
            default: '100%'
        } ,
        height: {
            type: String ,
            default: '100%'
        } ,
        chartData: {
            type: Object ,
            required: true
        }
    } ,
    data() {
        return {
            chart: null
        }
    } ,
    watch: {
        chartData: {
            deep: true ,
            handler(val) {
                this.setOptions(val)
            }
        }
    } ,
    mounted() {
        this.initChart()
        this.__resizeHandler = debounce(() => {
            if (this.chart) {
                this.chart.resize()
                this.initChart()
            }
        } , 100)
        window.addEventListener('resize' , this.__resizeHandler)
    } ,
    beforeDestroy() {
        if (!this.chart) {
            return
        }
        window.removeEventListener('resize' , this.__resizeHandler)
        this.chart.dispose()
        this.chart = null
    } ,
    methods: {
        initChart() {
            this.chart = echarts.init(this.$el , 'macarons')
            this.setOptions(this.chartData)
        } ,
        setOptions({
            name ,
            data
        }) {
            this.chart.setOption({
                color: [
                    '#ffd600' , '#38c143' , '#24a4ff' ,
                    '#2ec7c9' , '#b6a2de' , '#5ab1ef' , '#ffb980' , '#d87a80' ,
                    '#8d98b3' , '#57cfb4' , '#9190da' , '#cb90da' , '#ec69b9'
                ] ,
                tooltip: {
                    trigger: 'axis' ,
                    backgroundColor: 'rgba(1, 19, 35, 0.6)' ,
                    borderWidth: '1' ,
                    borderColor: 'rgba(2, 240, 255, 0.6)' ,
                    textStyle: {
                        fontSize: this.echartsToRem(12)
                    } ,
                    axisPointer: {
                        lineStyle: {
                            color: {
                                type: 'linear' ,
                                x: 0 ,
                                y: 0 ,
                                x2: 0 ,
                                y2: 1 ,
                                colorStops: [
                                    {
                                        offset: 0 ,
                                        color: 'rgba(200,200,200,0)' // 0% 处的颜色
                                    } , {
                                        offset: 0.5 ,
                                        color: 'rgba(2,240,255,1)' // 100% 处的颜色
                                    } , {
                                        offset: 1 ,
                                        color: 'rgba(151,151,151,0)' // 100% 处的颜色
                                    }
                                ] ,
                                global: false // 缺省为 false
                            }
                        }
                    }
                } ,
                legend: {
                    align: 'left' ,
                    bottom: '5%' ,
                    type: 'plain' ,
                    textStyle: {
                        color: '#fff' ,
                        fontSize: this.echartsToRem(14)
                    } ,
                    icon: 'circle' ,
                    itemGap: this.echartsToRem(25) ,
                    itemWidth: this.echartsToRem(12) ,
                    itemHeight: this.echartsToRem(12) ,
                    data: data.legend
                } ,
                grid: {
                    top: '15%' ,
                    left: '10%' ,
                    right: '10%' ,
                    bottom: '20%'
                    // containLabel: true
                } ,
                xAxis: [
                    {
                        type: 'category' ,
                        boundaryGap: false ,
                        axisLine: { // 坐标轴轴线相关设置。数学上的x轴
                            show: true ,
                            lineStyle: {
                                color: '#979797'
                            }
                        } ,
                        axisLabel: { // 坐标轴刻度标签的相关设置
                            textStyle: {
                                color: '#fff' ,
                                padding: this.echartsToRem(8) ,
                                fontSize: this.echartsToRem(14)
                            }
                        } ,
                        splitArea: { show: false } ,
                        splitLine: {
                            show: false ,
                            lineStyle: {
                                color: '#979797'
                            }
                        } ,
                        axisTick: { show: false } ,
                        data: data.xLabel
                    }
                ] ,
                yAxis: [
                    {
                        splitLine: { show: false } ,
                        axisLine: {
                            show: true ,
                            lineStyle: {
                                color: '#979797'
                            }
                        } ,
                        splitArea: { show: false } ,
                        axisLabel: {
                            show: true ,
                            textStyle: {
                                color: '#fff' ,
                                fontSize: this.echartsToRem(14)
                            }
                        } ,
                        axisTick: { show: false }
                    } ,
                    {
                        splitLine: { show: false } ,
                        axisLine: {
                            show: false ,
                            lineStyle: {
                                color: '#979797'
                            }
                        } ,
                        splitArea: { show: false } ,
                        axisLabel: {
                            show: true ,
                            textStyle: {
                                color: '#fff' ,
                                fontSize: this.echartsToRem(14)
                            }
                        } ,
                        axisTick: { show: false }
                    }
                ] ,
                series: data.series
            } , true)
        }
    }
}
</script>
