<template>
<div class = "monitor-con">
    <big-data-head name = "大王街道垃圾分类监管平台" company-name = "安徽省滁州市中新苏滁高新区"></big-data-head>
    <div class = "processing-con">
        <left-con></left-con>
        <middle-con></middle-con>
        <right-con></right-con>
    </div>
</div>
</template>

<script>
import BigDataHead from '../components/BigDataHead';
import LeftCon from './left';
import MiddleCon from './middle';
import RightCon from './right';

export default {
    name: 'Processing' ,
    components: {
        BigDataHead ,
        LeftCon ,
        MiddleCon ,
        RightCon
    }
}
</script>

<style scoped lang = "scss">
.monitor-con {
   background: #00080d;
   color: #ffffff;
   height: 100%;
   min-width: 1000px;
   .processing-con {
      overflow: hidden;
      height: calc(100% - 60px);
      min-width: 1280px;
      min-height: 720px;
      background-size: 100%;
      padding: 20px;
      display: flex;
      justify-content: space-between;
   }
}
</style>
