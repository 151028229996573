import Vue from 'vue'
import App from './App.vue'
import * as filters from '@/filter';
import '@/mixin/EchartsToRem';

// 设置 rem 函数
function setRem() {
    let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
    let htmlDom = document.getElementsByTagName('html')[0];
    htmlDom.style.fontSize = htmlWidth / 10 + 'px';
}

// 初始化
setRem();

// 改变窗口大小时重新设置 rem
window.onresize = function() {
    setRem()
}

Vue.config.productionTip = false;

Object.keys(filters).forEach(key => {
    Vue.filter(key , filters[key]);
});

new Vue({
    render: h => h(App)
}).$mount('#app')
