<template>
<div class = "monitor-head">
    <span>{{ companyName }}</span>
    <span>{{ nowDate | parseTime('{y}-{m}-{d}  {h}:{i}:{s}') }}</span>
    <div class = "monitor-head-title cp" @click = "toggerMenu">{{ name }}</div>
</div>
</template>

<script>
export default {
    name: 'BigDataHead' ,
    data() {
        return {
            nowDate: '' ,
            timer1: null ,
        }
    } ,
    computed: {} ,
    props: {
        name: {
            type: String ,
            default: ''
        } ,
        companyName: {
            type: String ,
            default: ""
        }
    } ,
    created() {
        this.nowDate = Date.now()
        this.startSetInterval()
    } ,
    beforeDestroy() {
        clearInterval(this.timer1)
        this.timer1 = null
    } ,
    methods: {
        startSetInterval() {
            this.timer1 = setInterval(() => {
                this.nowDate = Date.now()
            } , 1000)
        } ,
        toggerMenu() {
        }
    }
}
</script>

<style scoped lang = "scss">
.monitor-head {
   display: flex;
   justify-content: space-between;
   align-items: center;
   height: 60px;
   padding: 0 20px 0 62px;
   background: url("~@/assets/images/bigdata/top.png") no-repeat;
   background-size: 100% 100%;
   font-size: 24px;
   font-weight: 400;
   position: relative;
   &-title {
      position: absolute;
      top: 0;
      left: 0;
      height: 60px;
      font-size: 34px;
      text-align: center;
      line-height: 56px;
      width: 100%;
      font-weight: 800;
      color: #ffffff;
   }
}
</style>
